.mainContainer {
  width: 400px;
  background-color: #ffffff;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  z-index: -999999;
  padding: 20px;
  .headingContainer {
    text-align: center;
    .heading {
    }
  }

  .infoContainer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    font-size: 14px;
    .text {
      color: #9fa4a8;
    }
  }
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    .loader {
      width: 30px;
      height: 30px;
      color: #191515;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .button {
      outline: none;
      border: none;
      background-color: #f68f28;
      color: #ffffff;
      padding: 15px 30px;
      border-radius: 10px;
      cursor: pointer;
      opacity: 1;
    }

    .buttonDisabled {
      outline: none;
      border: none;
      background-color: #f68f28;
      color: #ffffff;
      padding: 15px 30px;
      border-radius: 10px;
      cursor: pointer;
      opacity: 0.5;
    }
  }
}

.mainContainerActive {
  top: 50%;
  transition: 0.3s all ease-in-out;
  opacity: 1;
  z-index: 999999;
}
