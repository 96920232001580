.mainContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f68f28;
  .loadingContainer {
    .loader {
      width: 80px;
      height: 80px;
      color: #ffffff;
    }
  }
}
