@media only screen and (min-width: 992px) {
  .mainContainer {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    .headingContainer {
      width: 40%;
      text-align: center;
      .heading {
        color: #ffffff;
        font-size: 40px;
      }
      .description {
        color: #ffffff80;
      }
    }
    .menusContainer {
      width: 80%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 40px;
      gap: 50px;
      margin-top: 40px;
      .menu {
        padding: 20px;
        border: 1px solid #f68f28;
        transition: 0.5s all ease-in-out;
        .iconContainer {
          width: 60px;
          height: auto;
          .icon {
            width: 100%;
            height: 100%;
            path {
              transition: 0.5s all ease-in-out;
              stroke: #f68f28;
            }
          }
        }
        .heading {
          color: #ffffff;
          font-size: 20px;
          margin-top: 40px;
        }
        .description {
          color: #ffffff80;
          font-size: 14px;
          margin-top: 10px;
        }

        .button {
          padding: 10px 0;
          color: #ffffff;
          display: inline-flex;
          padding: 10px 0;
          color: #ffffff;
          position: relative;
          margin-top: 20px;
          &::after {
            transition: 0.3s all ease-in-out;
            content: "";
            position: absolute;
            height: 2px;
            background-color: #ffffff;
            width: 0;
            bottom: 0;
            left: 0;
          }
        }
      }

      .menu:hover {
        border-radius: 10px;
        padding: 20px;
        border: 1px solid #f68f28;
        background-color: #f68f28;
        transition: 0.5s all ease-in-out;
        .iconContainer {
          width: 60px;
          height: auto;
          .icon {
            width: 100%;
            height: 100%;
            path {
              transition: 0.5s all ease-in-out;
              stroke: #ffffff;
            }
          }
        }
        .heading {
          color: #ffffff;
          font-size: 20px;
          margin-top: 40px;
        }
        .description {
          color: #ffffff80;
          font-size: 14px;
          margin-top: 10px;
        }

        .button {
          padding: 10px 0;
          color: #ffffff;
          display: inline-flex;
          padding: 10px 0;
          color: #ffffff;
          position: relative;
          margin-top: 20px;
          &::after {
            transition: 0.3s all ease-in-out;
            content: "";
            position: absolute;
            height: 2px;
            background-color: #ffffff;
            width: 100%;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .mainContainer {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    .headingContainer {
      width: 40%;
      text-align: center;
      .heading {
        color: #ffffff;
        font-size: 40px;
      }
      .description {
        color: #ffffff80;
      }
    }
    .menusContainer {
      width: 80%;
      display: grid;
      grid-template-columns: 1fr;
      padding: 40px;
      gap: 50px;
      margin-top: 40px;
      .menu {
        padding: 20px;
        border: 1px solid #f68f28;
        transition: 0.5s all ease-in-out;
        .iconContainer {
          width: 60px;
          height: auto;
          .icon {
            width: 100%;
            height: 100%;
            path {
              transition: 0.5s all ease-in-out;
              stroke: #f68f28;
            }
          }
        }
        .heading {
          color: #ffffff;
          font-size: 20px;
          margin-top: 40px;
        }
        .description {
          color: #ffffff80;
          font-size: 14px;
          margin-top: 10px;
        }

        .button {
          padding: 10px 0;
          color: #ffffff;
          display: inline-flex;
          padding: 10px 0;
          color: #ffffff;
          position: relative;
          margin-top: 20px;
          &::after {
            transition: 0.3s all ease-in-out;
            content: "";
            position: absolute;
            height: 2px;
            background-color: #ffffff;
            width: 0;
            bottom: 0;
            left: 0;
          }
        }
      }

      .menu:hover {
        border-radius: 10px;
        padding: 20px;
        border: 1px solid #f68f28;
        background-color: #f68f28;
        transition: 0.5s all ease-in-out;
        .iconContainer {
          width: 60px;
          height: auto;
          .icon {
            width: 100%;
            height: 100%;
            path {
              transition: 0.5s all ease-in-out;
              stroke: #ffffff;
            }
          }
        }
        .heading {
          color: #ffffff;
          font-size: 20px;
          margin-top: 40px;
        }
        .description {
          color: #ffffff80;
          font-size: 14px;
          margin-top: 10px;
        }

        .button {
          padding: 10px 0;
          color: #ffffff;
          display: inline-flex;
          padding: 10px 0;
          color: #ffffff;
          position: relative;
          margin-top: 20px;
          &::after {
            transition: 0.3s all ease-in-out;
            content: "";
            position: absolute;
            height: 2px;
            background-color: #ffffff;
            width: 100%;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .mainContainer {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    .headingContainer {
      width: 40%;
      text-align: center;
      .heading {
        color: #ffffff;
        font-size: 40px;
      }
      .description {
        color: #ffffff80;
      }
    }
    .menusContainer {
      width: 90%;
      display: grid;
      grid-template-columns: 1fr;
      padding: 40px;
      gap: 50px;
      margin-top: 40px;
      .menu {
        padding: 20px;
        border: 1px solid #f68f28;
        transition: 0.5s all ease-in-out;
        .iconContainer {
          width: 60px;
          height: auto;
          .icon {
            width: 100%;
            height: 100%;
            path {
              transition: 0.5s all ease-in-out;
              stroke: #f68f28;
            }
          }
        }
        .heading {
          color: #ffffff;
          font-size: 20px;
          margin-top: 40px;
        }
        .description {
          color: #ffffff80;
          font-size: 14px;
          margin-top: 10px;
        }

        .button {
          padding: 10px 0;
          color: #ffffff;
          display: inline-flex;
          padding: 10px 0;
          color: #ffffff;
          position: relative;
          margin-top: 20px;
          &::after {
            transition: 0.3s all ease-in-out;
            content: "";
            position: absolute;
            height: 2px;
            background-color: #ffffff;
            width: 0;
            bottom: 0;
            left: 0;
          }
        }
      }

      .menu:hover {
        border-radius: 10px;
        padding: 20px;
        border: 1px solid #f68f28;
        background-color: #f68f28;
        transition: 0.5s all ease-in-out;
        .iconContainer {
          width: 60px;
          height: auto;
          .icon {
            width: 100%;
            height: 100%;
            path {
              transition: 0.5s all ease-in-out;
              stroke: #ffffff;
            }
          }
        }
        .heading {
          color: #ffffff;
          font-size: 20px;
          margin-top: 40px;
        }
        .description {
          color: #ffffff80;
          font-size: 14px;
          margin-top: 10px;
        }

        .button {
          padding: 10px 0;
          color: #ffffff;
          display: inline-flex;
          padding: 10px 0;
          color: #ffffff;
          position: relative;
          margin-top: 20px;
          &::after {
            transition: 0.3s all ease-in-out;
            content: "";
            position: absolute;
            height: 2px;
            background-color: #ffffff;
            width: 100%;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    // width: 100%;
    .headingContainer {
      width: 90%;
      text-align: center;
      .heading {
        color: #ffffff;
        font-size: 40px;
      }
      .description {
        color: #ffffff80;
      }
    }
    .menusContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      padding: 20px;
      gap: 50px;
      margin-top: 40px;
      .menu {
        padding: 20px;
        border: 1px solid #f68f28;
        transition: 0.5s all ease-in-out;
        .iconContainer {
          width: 60px;
          height: auto;
          .icon {
            width: 100%;
            height: 100%;
            path {
              transition: 0.5s all ease-in-out;
              stroke: #f68f28;
            }
          }
        }
        .heading {
          color: #ffffff;
          font-size: 20px;
          margin-top: 40px;
        }
        .description {
          color: #ffffff80;
          font-size: 14px;
          margin-top: 10px;
        }

        .button {
          padding: 10px 0;
          color: #ffffff;
          display: inline-flex;
          padding: 10px 0;
          color: #ffffff;
          position: relative;
          margin-top: 20px;
          &::after {
            transition: 0.3s all ease-in-out;
            content: "";
            position: absolute;
            height: 2px;
            background-color: #ffffff;
            width: 0;
            bottom: 0;
            left: 0;
          }
        }
      }

      .menu:hover {
        border-radius: 10px;
        padding: 20px;
        border: 1px solid #f68f28;
        background-color: #f68f28;
        transition: 0.5s all ease-in-out;
        .iconContainer {
          width: 60px;
          height: auto;
          .icon {
            width: 100%;
            height: 100%;
            path {
              transition: 0.5s all ease-in-out;
              stroke: #ffffff;
            }
          }
        }
        .heading {
          color: #ffffff;
          font-size: 20px;
          margin-top: 40px;
        }
        .description {
          color: #ffffff80;
          font-size: 14px;
          margin-top: 10px;
        }

        .button {
          padding: 10px 0;
          color: #ffffff;
          display: inline-flex;
          padding: 10px 0;
          color: #ffffff;
          position: relative;
          margin-top: 20px;
          &::after {
            transition: 0.3s all ease-in-out;
            content: "";
            position: absolute;
            height: 2px;
            background-color: #ffffff;
            width: 100%;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}
