.mainContainer {
  width: 100%;
  .boardContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    // height: 40%;
    .board {
      width: 80%;
      // height: 100%;
      .svgContainer {
        .border {
          fill: #3f3f3f;
        }
        .button {
          fill: #191515;
          stroke: none;
          filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.336));
          transition: 0.3s all ease-in-out;
        }
        .buttonActive {
          fill: #191515;
          stroke: none;
          filter: drop-shadow(0px 0px 15px #fc0fb1);
          transition: 0.3s all ease-in-out;
        }
      }
    }
  }
}
