.mainContainer {
  display: flex;
  justify-content: center;
  .bodyContainer {
    width: 60%;
    .headingContainer {
      margin-top: 20px;
      text-align: center;
      .heading {
        color: #ffffff;
      }
    }
    .animationContainer {
      .headingContainer {
        margin-top: 40px;
        text-align: center;
        .heading {
          color: #ffffff;
        }
      }

      .animationListContainer {
        background-color: #17151d;
        margin: 30px 30px;
        padding: 30px;
        border: 1px solid #2f2b2b;
        border-radius: 10px;
        .list {
          display: flex;
          align-items: center;
          padding: 15px 10px;
          border-radius: 10px;
          cursor: pointer;
          transition: 0.3s all ease-in-out;
          &:hover {
            background-color: #bdbdbd40;
          }
          .checkboxContainer {
          }
          .title {
            margin-left: 20px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
