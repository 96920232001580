.mainContainer {
  padding: 20px;
  .errorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    .error {
      color: #191515;
      font-size: 20px;
    }
  }
  .headingContainer {
    .heading {
      font-weight: 500;
    }
  }
  .contentsContainer {
    margin-top: 40px;
    .headerContainer {
      display: flex;
      align-items: flex-start;
      background-color: #d2d2d2;
      border-radius: 10px;
      gap: 20px;
      position: sticky;
      top: 0;
      .header {
        flex: 1;
        // background-color: yellow;
        padding: 15px 10px;
      }
    }

    .bodyContainer {
      background-color: #f2f2f2;
      border-radius: 10px;
      margin-top: 10px;
      .row {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        .item {
          flex: 1;
          padding: 15px 10px;
          flex-wrap: wrap;
          word-break: break-all;
        }
      }
    }
  }
}
