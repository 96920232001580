@media only screen and (min-width: 992px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 20px 40px;
    background-image: url("../../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 5%;
    .infoContainer {
      width: 40%;
      .subHeading {
        background-color: #f68f28;
        display: inline-flex;
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
        color: #ffffff;
      }
      .heading {
        color: #ffffff;
        font-size: 50px;
        margin-top: 20px;
      }
      .description {
        color: #ffffff80;
      }
      .menu {
        display: inline-flex;
        padding: 10px 40px;
        background-color: #ffffff;
        color: #000000;
        border-radius: 10px;
        margin-top: 30px;
        transition: 0.3s all ease-in-out;
        &:hover {
          background-color: #f68f28;
          color: #ffffff;
        }
      }
    }

    .imageContainer {
      width: 40%;
      height: auto;
      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 20px 40px;
    background-image: url("../../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10%;
    .infoContainer {
      width: 100%;
      .subHeading {
        background-color: #f68f28;
        display: inline-flex;
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
        color: #ffffff;
      }
      .heading {
        color: #ffffff;
        font-size: 50px;
        margin-top: 20px;
      }
      .description {
        color: #ffffff80;
      }
      .menu {
        display: inline-flex;
        padding: 10px 40px;
        background-color: #ffffff;
        color: #000000;
        border-radius: 10px;
        margin-top: 30px;
        transition: 0.3s all ease-in-out;
        &:hover {
          background-color: #f68f28;
          color: #ffffff;
        }
      }
    }

    .imageContainer {
      width: 100%;
      height: auto;
      margin-top: 100px;
      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 20px 40px;
    background-image: url("../../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5%;
    .infoContainer {
      width: 100%;
      margin-top: 40px;
      .subHeading {
        background-color: #f68f28;
        display: inline-flex;
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
        color: #ffffff;
      }
      .heading {
        color: #ffffff;
        font-size: 50px;
        margin-top: 20px;
      }
      .description {
        color: #ffffff80;
      }
      .menu {
        display: inline-flex;
        padding: 10px 40px;
        background-color: #ffffff;
        color: #000000;
        border-radius: 10px;
        margin-top: 30px;
        transition: 0.3s all ease-in-out;
        &:hover {
          background-color: #f68f28;
          color: #ffffff;
        }
      }
    }

    .imageContainer {
      width: 80%;
      height: auto;
      margin-top: 100px;
      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 20px 40px;
    background-image: url("../../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5%;
    .infoContainer {
      width: 100%;
      margin-top: 40px;
      .subHeading {
        background-color: #f68f28;
        display: inline-flex;
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
        color: #ffffff;
      }
      .heading {
        color: #ffffff;
        font-size: 40px;
        margin-top: 20px;
      }
      .description {
        color: #ffffff80;
      }
      .menu {
        display: inline-flex;
        padding: 10px 40px;
        background-color: #ffffff;
        color: #000000;
        border-radius: 10px;
        margin-top: 30px;
        transition: 0.3s all ease-in-out;
        &:hover {
          background-color: #f68f28;
          color: #ffffff;
        }
      }
    }

    .imageContainer {
      width: 80%;
      height: auto;
      margin-top: 100px;
      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
}
