.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  .loader {
    width: 30px;
    height: 30px;
    color: #191515;
  }
}
