.mainContainer {
  width: 400px;
  background-color: #ffffff;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  z-index: -999999;
  padding: 20px;
  .headingContainer {
    text-align: center;
    .heading {
    }
  }
  .inputContainer {
    margin: 20px 0;
    .label {
      font-size: 12px;
      color: #5f6163;
    }

    .input {
      width: 100%;
      margin-top: 15px;
      border: 1px solid #a5a9ac;
      padding: 10px;
      border-radius: 5px;
      outline: none;
    }
  }

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .loader {
      width: 30px;
      height: 30px;
      color: #191515;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      outline: none;
      border: none;
      background-color: #f68f28;
      color: #ffffff;
      padding: 15px 30px;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}

.mainContainerActive {
  top: 50%;
  transition: 0.3s all ease-in-out;
  opacity: 1;
  z-index: 999999;
}
