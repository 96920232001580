.mainContainer {
  .bannerSection {
    background: url("../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 980px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .col6 {
      width: 50%;
      padding-inline: 16px;
    }

    .bannerInfo {
      width: 100%;
      max-width: 646px;
      .bannerSubHeading {
        display: inline-block;
        font-size: 18px;
        font-weight: normal;
        padding: 6px 13px;
        background: #f68f28;
        color: #fff;
        border-radius: 6px;
        margin-bottom: 20px;
      }
      .bannerHeading {
        font-size: 72px;
        line-height: 85px;
        font-weight: bold;
        color: #fff;
        span {
          border-bottom: 4px solid #f68f28;
        }
      }
      .bannerPara {
        font-size: 20px;
        font-weight: normal;
        line-height: 28px;
        color: #8d8d8d;
        padding-block: 1.5rem;
      }
      .bannerBtn {
        display: inline-block;
        padding: 15px 35px;
        background-color: #fff;
        border-radius: 8px;
        font-size: 24px;
        font-weight: 300;
        color: #000;
      }
    }
    .BannerSideImage {
      img {
        width: 100%;
        max-width: 660px;
        height: auto;
      }
      display: flex;
      justify-content: flex-end;
    }
    .scrollBtn {
      button {
        font-size: 14px;
        color: #848181;
        line-height: 28px;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        border: 0;
        outline: 0;
        img {
          margin: 0 auto;
        }
      }
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .menuSection {
    background: #000000;
    padding: 50px 0;
    position: relative;
    .container {
      width: 100%;
      max-width: 1160px;
      padding-left: 15px;
      padding-right: 15px;
      margin: 0 auto;
      .mainContent {
        width: 100%;
        max-width: 825px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 30px;
        .mainMenuHeading {
          font-size: 48px;
          line-height: 85px;
          font-weight: bold;
          color: #ffffff;
        }
        .mainMenuContent {
          font-size: 20px;
          line-height: 28px;
          color: #8d8d8d;
        }
      }
      .boxWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px;
        .boxContent {
          border: 0.1px solid #ffffff5c;
          padding: 40px 22px;
          position: relative;
          &:hover {
            background: #f68f28;
            .boxContentMicro {
              .boxLinkWrapper {
                .boxLink {
                  &::before {
                    width: calc(100% + 30px);
                  }
                }
              }
            }
            .boxContentProfile {
              .boxLinkWrapper {
                .boxLink {
                  &::before {
                    width: calc(100% + 30px);
                  }
                }
              }
            }
            .boxContentEvents {
              .boxLinkWrapper {
                .boxLink {
                  &::before {
                    width: calc(100% + 30px);
                  }
                }
              }
            }
            .boxContentHelp {
              .boxLinkWrapper {
                .boxLink {
                  &::before {
                    width: calc(100% + 30px);
                  }
                }
              }
            }
          }
          .boxContentMicro {
            display: grid;

            &:before {
              content: "";
              width: 115px;
              height: 115px;
              position: absolute;
              /* background: url("../../Assets/micro-bg.png"); */
              background-repeat: no-repeat;
              background-size: cover;
              bottom: 0;
              right: 0;
            }
            .imageWrapper {
              display: grid;
              margin-bottom: 50px;
              img {
                width: 52px;
                height: 52px;
              }
            }
            .boxHeading {
              font-size: 22px;
              line-height: 30px;
              color: #ffffff;
            }
            .boxPara {
              font-size: 16px;
              line-height: 24px;
              color: #cdcbcb;
              margin-bottom: 40px;
            }
            .boxLinkWrapper {
              display: flex;
              align-items: center;
              position: relative;
              .boxLink {
                font-size: 18px;
                line-height: 26px;
                color: #ffffff;
                position: relative;
                &::before {
                  content: "";
                  height: 1px;
                  background: #fff;
                  width: -webkit-fill-available;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  display: inline-block;
                }
              }
              img {
                margin-left: 10px;
              }
            }
          }
          .boxContentProfile {
            display: grid;
            &:before {
              content: "";
              width: 115px;
              height: 115px;
              position: absolute;
              /* background: url("../../Assets/profile-bg.png"); */
              background-repeat: no-repeat;
              background-size: cover;
              bottom: 0;
              right: 0;
            }
            .imageWrapper {
              display: grid;
              margin-bottom: 50px;
              img {
                width: 52px;
                height: 52px;
              }
            }
            .boxHeading {
              font-size: 22px;
              line-height: 30px;
              color: #ffffff;
            }
            .boxPara {
              font-size: 16px;
              line-height: 24px;
              color: #cdcbcb;
              margin-bottom: 40px;
            }
            .boxLinkWrapper {
              display: flex;
              align-items: center;
              position: relative;
              .boxLink {
                font-size: 18px;
                line-height: 26px;
                color: #ffffff;
                position: relative;
                &::before {
                  content: "";
                  height: 1px;
                  background: #fff;
                  width: -webkit-fill-available;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  display: inline-block;
                }
              }
              img {
                margin-left: 10px;
              }
            }
          }
          .boxContentEvents {
            display: grid;
            &:before {
              content: "";
              width: 115px;
              height: 115px;
              position: absolute;
              /* background: url("../../Assets/events-bg.png"); */
              background-repeat: no-repeat;
              background-size: cover;
              bottom: 0;
              right: 0;
            }
            .imageWrapper {
              display: grid;
              margin-bottom: 50px;
              img {
                width: 52px;
                height: 52px;
              }
            }
            .boxHeading {
              font-size: 22px;
              line-height: 30px;
              color: #ffffff;
            }
            .boxPara {
              font-size: 16px;
              line-height: 24px;
              color: #cdcbcb;
              margin-bottom: 40px;
            }
            .boxLinkWrapper {
              display: flex;
              align-items: center;
              position: relative;
              .boxLink {
                font-size: 18px;
                line-height: 26px;
                color: #ffffff;
                position: relative;
                &::before {
                  content: "";
                  height: 1px;
                  background: #fff;
                  width: -webkit-fill-available;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  display: inline-block;
                }
              }
              img {
                margin-left: 10px;
              }
            }
          }
          .boxContentHelp {
            display: grid;
            &:before {
              content: "";
              width: 115px;
              height: 115px;
              position: absolute;
              /* background: url("../../Assets/help-bg.png"); */
              background-repeat: no-repeat;
              background-size: cover;
              bottom: 0;
              right: 0;
            }
            .imageWrapper {
              display: grid;
              margin-bottom: 50px;
              img {
                width: 52px;
                height: 52px;
              }
            }
            .boxHeading {
              font-size: 22px;
              line-height: 30px;
              color: #ffffff;
            }
            .boxPara {
              font-size: 16px;
              line-height: 24px;
              color: #cdcbcb;
              margin-bottom: 40px;
            }
            .boxLinkWrapper {
              display: flex;
              align-items: center;
              position: relative;
              .boxLink {
                font-size: 18px;
                line-height: 26px;
                color: #ffffff;
                position: relative;
                &::before {
                  content: "";
                  height: 1px;
                  background: #fff;
                  width: -webkit-fill-available;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  display: inline-block;
                }
              }
              img {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    &::before {
      content: "";
      /* background: url("../../Assets/orange-bg.svg"); */
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
      width: 500px;
      height: 500px;
      position: absolute;
      z-index: 99;
      left: -100px;
      bottom: -90px;
    }
  }
}
