.mainContainer {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .headingContainer {
    text-align: center;
    .heading {
      color: #ffffff;
    }
  }
  .controlContainer {
    background-color: #17151d;
    border: 1px solid #483b3b;
    width: 50%;
    margin-top: 50px;
    border-radius: 10px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .iconContainer {
      width: 60px;
      height: auto;
      align-self: center;
      margin-top: 20px;
      .icon {
        width: 100%;
        height: 100%;
      }
    }
    .brightnessContainer {
      margin-top: 130px;
      .brightnessTrack {
        position: relative;
        width: 100%;
        height: 20px;
        background-color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
        .brightnessThumb {
          position: absolute;
          height: 100%;
          background-image: repeating-linear-gradient(
            120deg,
            #f68f28,
            #f68f28 10px,
            #f8a857 10px,
            #f8a857 20px /* determines size */
          );
          border-radius: 5px;
        }
        .percentageContainer {
          position: absolute;
          top: -100%;
          left: 0%;
          transform: translateX(-50%);
          margin-top: -50px;
          border: 1px solid #f68f28;
          border-radius: 10px;
          padding: 3px;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            width: 0px;
            height: 0px;
            left: 50%;
            bottom: -15px;
            transform: translateX(-50%);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #f68f28;
            border-bottom: 0 solid transparent;
          }
          .percentage {
            background-color: #f68f28;
            // padding: 10px 20px;
            width: 60px;
            padding: 10px 0;
            border-radius: 8px;
            color: #ffffff;
            font-weight: bold;
          }
        }
      }
    }
  }
}
