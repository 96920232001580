.mainContainer {
  width: 100%;
  padding: 30px 20px;
  background: #000000;
  .footerTop {
    .logoContainer {
      width: 150px;
      height: auto;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
  }
  .footerBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #707070;
    padding-top: 30px;
    p {
      font-size: 18px;
      line-height: 27px;
      color: #acacac;
      font-weight: lighter;
    }
    .socialMediaContainer {
      display: flex;
      align-items: center;
      .socialMediaIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        width: 36px;
        height: 36px;
        border-radius: 50px;
        margin-right: 8px;
        img {
        }
      }
    }
  }
}
