.mainContainer {
  margin: 20px;
  .headingContainer {
    text-align: center;
    .heading {
      color: #ffffff;
    }
  }
  .infoContainer {
    padding: 0 30px;
    margin-top: 40px;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid grey;
      padding: 20px 0;
      &:nth-child(1){
        border-top: none;
      }
      p {
        &:nth-child(1) {
          color: #ffffff;
        }
        &:nth-child(2) {
          color: #f68f28;
        }
      }
    }
  }
}
