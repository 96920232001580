@media only screen and (min-width: 992px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-image: url("../../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 20px 30px;
    .logoContainer {
      width: 150px;
      height: auto;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
    .menusContainer {
      border: 2px solid #f68f28;
      border-radius: 10px;
      padding: 10px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .menu {
        font-size: 20px;
        font-weight: lighter;
        position: relative;
        padding: 8px 0;
        margin: 0 10px;
        color: #000000;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    .mobileMenuContainer {
      display: none;
      .buttonContainer {
        background: none;
        border: none;
        outline: none;
        width: 50px;
        height: 50px;
        cursor: pointer;
        .icon {
          width: 100%;
          height: 100%;
        }
      }
      .menuContainer {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        transition: 0.3s all ease-in-out;

        .menu {
          display: block;
          font-size: 20px;
          font-weight: lighter;
          padding: 8px 0;
          margin: 10px;
          color: #000000;
        }

        .closeButtonContainer {
          background: none;
          outline: none;
          border: none;
          width: 40px;
          height: auto;
          position: absolute;
          right: 20px;
          top: 20px;
          .icon {
            width: 100%;
            height: 100%;
          }
        }
      }

      .menuContainerActive {
        left: 0;
        transition: 0.3s all ease-in-out;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-image: url("../../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 20px 30px;
    .logoContainer {
      width: 150px;
      height: auto;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
    .menusContainer {
      display: none !important;
      border: 2px solid #f68f28;
      border-radius: 10px;
      padding: 10px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .menu {
        font-size: 20px;
        font-weight: lighter;
        padding: 8px 0;
        margin: 0 10px;
        color: #000000;
      }
      .menuActive {
        font-size: 20px;
        font-weight: lighter;
        position: relative;
        padding: 8px 0;
        margin: 0 10px;
        color: #000000;
        &::after {
          content: "";
          position: absolute;
          height: 4px;
          width: 100%;
          background-color: #f68f28;
          left: 0;
          bottom: 0;
          border-radius: 4px 4px 0 0;
        }
      }
    }

    .mobileMenuContainer {
      .buttonContainer {
        background: none;
        border: none;
        outline: none;
        width: 50px;
        height: 50px;
        cursor: pointer;
        .icon {
          width: 100%;
          height: 100%;
        }
      }
      .menuContainer {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        transition: 0.3s all ease-in-out;

        .menu {
          display: block;
          font-size: 20px;
          font-weight: lighter;
          padding: 8px 0;
          margin: 10px;
          color: #000000;
        }
        .menuActive {
          display: block;
          font-size: 20px;
          font-weight: lighter;
          position: relative;
          padding: 8px 0;
          margin: 10px;
          color: #000000;
          &::after {
            content: "";
            position: absolute;
            height: 4px;
            width: 100%;
            background-color: #f68f28;
            left: 0;
            bottom: 0;
            border-radius: 4px 4px 0 0;
          }
        }

        .closeButtonContainer {
          background: none;
          outline: none;
          border: none;
          width: 40px;
          height: auto;
          position: absolute;
          right: 20px;
          top: 20px;
          .icon {
            width: 100%;
            height: 100%;
          }
        }
      }

      .menuContainerActive {
        left: 0;
        transition: 0.3s all ease-in-out;
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-image: url("../../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 20px 30px;
    .logoContainer {
      width: 100px;
      height: auto;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
    .menusContainer {
      display: none !important;
      border: 2px solid #f68f28;
      border-radius: 10px;
      padding: 10px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .menu {
        font-size: 20px;
        font-weight: lighter;
        padding: 8px 0;
        margin: 0 10px;
        color: #000000;
      }
      .menuActive {
        font-size: 20px;
        font-weight: lighter;
        position: relative;
        padding: 8px 0;
        margin: 0 10px;
        color: #000000;
        &::after {
          content: "";
          position: absolute;
          height: 4px;
          width: 100%;
          background-color: #f68f28;
          left: 0;
          bottom: 0;
          border-radius: 4px 4px 0 0;
        }
      }
    }

    .mobileMenuContainer {
      .buttonContainer {
        background: none;
        border: none;
        outline: none;
        width: 40px;
        height: 40px;
        cursor: pointer;
        .icon {
          width: 100%;
          height: 100%;
        }
      }
      .menuContainer {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        transition: 0.3s all ease-in-out;

        .menu {
          display: block;
          font-size: 20px;
          font-weight: lighter;
          padding: 8px 0;
          margin: 10px;
          color: #000000;
        }
        .menuActive {
          display: block;
          font-size: 20px;
          font-weight: lighter;
          position: relative;
          padding: 8px 0;
          margin: 10px;
          color: #000000;
          &::after {
            content: "";
            position: absolute;
            height: 4px;
            width: 100%;
            background-color: #f68f28;
            left: 0;
            bottom: 0;
            border-radius: 4px 4px 0 0;
          }
        }

        .closeButtonContainer {
          background: none;
          outline: none;
          border: none;
          width: 40px;
          height: auto;
          position: absolute;
          right: 20px;
          top: 20px;
          .icon {
            width: 100%;
            height: 100%;
          }
        }
      }

      .menuContainerActive {
        left: 0;
        transition: 0.3s all ease-in-out;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-image: url("../../../Assets/HomeBackground.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 20px 30px;
    .logoContainer {
      width: 100px;
      height: auto;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
    .menusContainer {
      display: none !important;
      border: 2px solid #f68f28;
      border-radius: 10px;
      padding: 10px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .menu {
        font-size: 20px;
        font-weight: lighter;
        padding: 8px 0;
        margin: 0 10px;
        color: #000000;
      }
      .menuActive {
        font-size: 20px;
        font-weight: lighter;
        position: relative;
        padding: 8px 0;
        margin: 0 10px;
        color: #000000;
        &::after {
          content: "";
          position: absolute;
          height: 4px;
          width: 100%;
          background-color: #f68f28;
          left: 0;
          bottom: 0;
          border-radius: 4px 4px 0 0;
        }
      }
    }

    .mobileMenuContainer {
      .buttonContainer {
        background: none;
        border: none;
        outline: none;
        width: 40px;
        height: 40px;
        cursor: pointer;
        .icon {
          width: 100%;
          height: 100%;
        }
      }
      .menuContainer {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        transition: 0.3s all ease-in-out;

        .menu {
          display: block;
          font-size: 20px;
          font-weight: lighter;
          padding: 8px 0;
          margin: 10px;
          color: #000000;
        }
        .menuActive {
          display: block;
          font-size: 20px;
          font-weight: lighter;
          position: relative;
          padding: 8px 0;
          margin: 10px;
          color: #000000;
          &::after {
            content: "";
            position: absolute;
            height: 4px;
            width: 100%;
            background-color: #f68f28;
            left: 0;
            bottom: 0;
            border-radius: 4px 4px 0 0;
          }
        }

        .closeButtonContainer {
          background: none;
          outline: none;
          border: none;
          width: 40px;
          height: auto;
          position: absolute;
          right: 20px;
          top: 20px;
          .icon {
            width: 100%;
            height: 100%;
          }
        }
      }

      .menuContainerActive {
        left: 0;
        transition: 0.3s all ease-in-out;
      }
    }
  }
}
