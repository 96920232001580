.mainContainer {
  .headingContainer {
    text-align: center;
    margin-top: 30px;
    .heading {
      color: #ffffff;
    }
  }

  .colorPickerContainer {
    display: flex;
    justify-content: center;

    .colorPicker {
      background-color: #17151d;
      border: 2px solid #2f2b2b;
      border-radius: 10px;
      padding: 60px;
      margin: 20px;
      .headingContainer {
        margin-top: 0;
        .heading {
          font-weight: 400;
          font-size: 20px;
        }
      }

      .wheelContainer {
        margin-top: 30px;
        padding: 0 50px;
      }

      .shadeContainer {
        margin-top: 30px;
      }
      .hexContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        p {
          color: #ffffff;
          margin-right: 20px;
          font-size: 25px;
        }

        input {
          text-align: center;
          padding: 15px;
          font-size: 20px;
          font-weight: 400;
          width: 150px;
          border-radius: 10px;
          border: none;
          outline: none;
        }
      }
    }
  }
}
