.mainContainer {
  padding: 0 30px;
  position: relative;
  .iconContainer {
    width: 60px;
    position: absolute;
    top: 0;
    right: 10px;

    .icon {
      width: 100%;
      height: 100%;
      path {
        transition: 0.2s all ease-in-out;
      }
    }

    &:hover {
      .icon {
        path {
          transition: 0.2s all ease-in-out;
          fill: #323232;
        }
      }
    }
  }
  .headingContainer {
    text-align: center;
    padding: 20px 0;
    .heading {
      color: #ffffff;
    }
  }
  .firmwareContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .versionContainer {
      p {
        &:nth-child(1) {
          color: #ffffff80;
        }
        &:nth-child(2) {
          margin-top: 5px;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
    .updateContainer {
      p {
        &:nth-child(1) {
          color: #ffffff80;
        }
        &:nth-child(2) {
          margin-top: 5px;
          font-weight: bold;
          color: #ffffff;
          text-align: right;
        }
      }
    }
  }
  .descriptionContainer {
    margin-top: 40px;
    background-color: #17151d;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #2f2b2b;
    p {
      color: #ffffff90;
      font-size: 15px;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 20px 0;
    .button {
      outline: none;
      border: none;
      background-color: #f68f28;
      color: #ffffff;
      padding: 15px 30px;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
