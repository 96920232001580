.mainContainer {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  .disconnectButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 40px;
    width: 80%;
    .button {
      outline: none;
      border: 2px solid #f68f28;
      background-color: #393737;
      color: #ffffff;
      padding: 15px 50px;
      border-radius: 10px;
      cursor: pointer;
    }
  }
  .settingsContainer {
    border: 5px solid #2f2b2b;
    border-radius: 10px;
    width: 80%;
    .tabsContainer {
      display: flex;
      background-color: #191515;
      border: 1px solid #393737;
      border-radius: 5px;
      .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;
        cursor: pointer;
        position: relative;
        button {
          background: none;
          color: #ffffff;
          border: none;
          outline: none;
          cursor: pointer;
        }
        .dropdownContainer {
          position: absolute;
          top: 80%;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          background-color: #ffffff;
          opacity: 0;
          transition: 0.3s all ease-in-out;
          z-index: 9999999999;
          button {
            display: none;
            color: #000000;
            padding: 10px;
            width: 100%;
            &:nth-child(2) {
              border-top: 1px solid #d3d3d3;
              border-bottom: 1px solid #d3d3d3;
            }
            &:hover {
              background-color: #f68f28;
              color: #ffffff;
            }
          }
        }

        .dropdownContainerActive {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          background-color: #ffffff;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          opacity: 1;
          border-radius: 5px;
          transition: 0.3s all ease-in-out;
          z-index: 9999999999;
          button {
            display: block;
            color: #000000;
            padding: 10px;
            width: 100%;
            &:nth-child(2) {
              border-top: 1px solid #d3d3d3;
              border-bottom: 1px solid #d3d3d3;
            }
            &:hover {
              background-color: #f68f28;
              color: #ffffff;
              transition: 0.3s all ease-in-out;
            }
          }
        }
      }
      .tabActive {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #f68f28;
        border-radius: 5px;
        padding: 20px;
        cursor: pointer;
        position: relative;
        button {
          background: none;
          color: #ffffff;
          border: none;
          outline: none;
          cursor: pointer;
        }
        .dropdownContainer {
          position: absolute;
          top: 80%;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          background-color: #ffffff;
          opacity: 0;
          transition: 0.3s all ease-in-out;
          button {
            display: none;
            color: #000000;
            padding: 10px;
            width: 100%;
            &:nth-child(2) {
              border-top: 1px solid #d3d3d3;
              border-bottom: 1px solid #d3d3d3;
            }
            &:hover {
              background-color: #f68f28;
              color: #ffffff;
            }
          }
        }

        .dropdownContainerActive {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          background-color: #ffffff;
          opacity: 1;
          border-radius: 5px;
          transition: 0.3s all ease-in-out;
          button {
            display: block;
            color: #000000;
            padding: 10px;
            width: 100%;
            &:nth-child(2) {
              border-top: 1px solid #d3d3d3;
              border-bottom: 1px solid #d3d3d3;
            }
            &:hover {
              background-color: #f68f28;
              color: #ffffff;
              transition: 0.3s all ease-in-out;
            }
          }
        }
      }
    }
    .bodyContainer {
      padding: 10px;
      background-color: #0e0d0f;
      min-height: 60vh;
    }
  }
  .scanContainer {
    min-height: 50vh;
    width: 50%;
    border-radius: 20px;
    background-color: #2c2925;
    margin: 100px 0;
    .topContainer {
      padding: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f68f28;
      .heading {
        color: #ffffff;
      }
      .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        font-size: 16px;
        background-color: #ffffff;
        border: none;
        outline: none;
        border-radius: 10px;
        cursor: pointer;
        .iconContainer {
          width: 18px;
          height: auto;
          margin-left: 15px;
          .icon {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.supportContainer {
  background-color: #000000;
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    color: #ffffff;
  }
}
