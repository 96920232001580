.mainContainer {
  border: 1px solid #f68f28;
  border-radius: 50%;
  .iconContainer {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 2px;
    .icon {
      display: none;
      width: 100%;
      height: 100%;
    }
  }
}

.mainContainerActive {
  border: 1px solid #f68f28;
  border-radius: 50%;
  .iconContainer {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f68f28;
    margin: 2px;
    .icon {
      width: 100%;
      height: 100%;
    }
  }
}
