.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .formContainer {
    width: 300px;
    .loginContainer {
      width: 100%;
      .headingContainer {
        text-align: center;
        .heading {
          font-size: 40px;
        }
      }
      .inputContainer {
        width: 100%;
        margin: 25px 0;
        .label {
          font-size: 13px;
        }
        .input {
          width: 100%;
          margin-top: 5px;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #d3d3d3;
          outline: none;
          &:focus {
            border: 1px solid #f68f28;
          }
        }
      }

      .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        .button {
          background: none;
          outline: none;
          border: none;
          background-color: #f68f28;
          color: #ffffff;
          padding: 10px 30px;
          width: 50%;
          border-radius: 10px;
          transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s all;
          cursor: pointer;
          &:hover {
            scale: 0.9;
            transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s all;
            background-color: #d3d3d3;
          }
          &:active {
            scale: 0.9;
            transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s all;
            background-color: #d3d3d3;
            border: 1px solid #9b9999;
          }
        }
      }
      .loadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        .loader {
          width: 30px;
          height: 30px;
          color: #191515;
        }
      }
    }
  }
}
