.mainContainer {
  position: relative;
  .button {
    min-height: 30px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 12px;
    text-align: left;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #ffffff;
    opacity: 1;
    border-radius: 5px;
    overflow: hidden;
    transition: 0.3s all ease-in-out;
    z-index: 9999999999;
    opacity: 0;
    button {
      display: none;
      color: #000000;
      padding: 10px;
      width: 100%;
      outline: none;
      border: none;
      cursor: pointer;
      border-bottom: 1px solid #d3d3d3;
      text-align: left;
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #f68f28;
        color: #ffffff;
        transition: 0.3s all ease-in-out;
      }
    }
  }

  .dropdownActive {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    opacity: 1;
    border-radius: 5px;
    overflow: hidden;
    transition: 0.3s all ease-in-out;
    z-index: 9999999999;
    opacity: 1;
    max-height: 150px;
    overflow: auto;
    scrollbar-width: thin !important;
    button {
      display: block;
      color: #000000;
      padding: 10px;
      width: 100%;
      outline: none;
      border: none;
      cursor: pointer;
      border-bottom: 1px solid #d3d3d3;
      text-align: left;
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #f68f28;
        color: #ffffff;
        transition: 0.3s all ease-in-out;
      }
    }
  }
}
