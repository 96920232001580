.mainContainer {
  min-height: 80vh;
  background-color: #000000;
  width: 100%;
  padding: 40px;
  .headingContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    .heading {
      color: #ffffff;
      font-size: 40px;
    }
  }

  .faqsContainer {
    .faq {
      margin: 50px 0;
      .questionContainer {
        .label {
          color: #f68f28;
          font-size: 12px;
        }
        .question {
          color: #ffffff;
          margin-top: 5px;
        }
      }
      .answerContainer {
        margin-top: 20px;
        .label {
          color: #f68f28;
          font-size: 12px;
        }
        .answer {
          margin-top: 5px;
          color: #ffffff;
        }
      }
    }
  }
}
