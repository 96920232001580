.mainContainer {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  .sidebarContainer {
    width: 25%;
    border: 2px solid #2f2b2b;
    border-radius: 10px;
    .dropdownContainer {
      margin: 10px;
      position: relative;
      .button {
        width: 100%;
        background: none;
        border: none;
        outline: none;
        color: #ffffff;
        border: 1px solid #f68f28;
        padding: 10px 0;
        border-radius: 5px;
        cursor: pointer;
      }
      .dropdown {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        background-color: #ffffff;
        opacity: 0;
        transition: 0.3s all ease-in-out;
        z-index: 9999999999;
        button {
          display: none;
          color: #000000;
          padding: 10px;
          width: 100%;
          cursor: pointer;
          &:nth-child(2) {
            border-top: 1px solid #d3d3d3;
            border-bottom: 1px solid #d3d3d3;
          }
          &:hover {
            background-color: #f68f28;
            color: #ffffff;
          }
        }
      }

      .dropdownActive {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        background-color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        opacity: 1;
        border-radius: 5px;
        overflow: hidden;
        transition: 0.3s all ease-in-out;
        z-index: 9999999999;
        button {
          display: block;
          color: #000000;
          padding: 10px;
          width: 100%;
          outline: none;
          border: none;
          cursor: pointer;
          border-bottom: 1px solid #d3d3d3;
          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background-color: #f68f28;
            color: #ffffff;
            transition: 0.3s all ease-in-out;
          }
        }
      }
    }

    .buttonsContainer {
      margin-top: 20px;
    }

    .saveContainer {
      .button {
        width: 100%;
        background-color: #f68f28;
        outline: none;
        border: none;
        color: #ffffff;
        border-radius: 10px;
        padding: 15px 0;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        &:active {
          scale: 0.95;
          transition: 0.1s all ease-in-out;
        }
      }
    }
  }
  .boardContainer {
    width: 100%;
    border-radius: 10px;
  }
}
