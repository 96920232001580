.mainContainer {
  position: relative;
  .buttonContainer {
    position: relative;
    .button {
      margin: 1px 0;
      outline: none;
      border: none;
      background: none;
      color: #ffffff;
      font-size: 15px;
      width: 100%;
      text-align: left;
      padding: 15px 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s all ease-in-out;
      &:hover {
        background-color: #f68f28;
      }
    }

    .buttonActive {
      background-color: #f68f28;
    }
  }

  .modalContainer {
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translateY(-50%);
    background-color: #130e0e;
    border: 1px solid #2f2b2b;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    opacity: 0;
    padding: 10px;
    width: 250px;
    z-index: -1;
    .bodyContainer {
      .dropdownContainer {
        margin: 20px 0;
        .label {
          color: #ffffff;
          font-size: 12px;
          cursor: pointer;
        }
        .dropdown {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }

  .modalContainerActive {
    position: absolute;
    top: 50%;
    left: 105%;
    transform: translateY(-50%);
    background-color: #130e0e;
    border: 1px solid #2f2b2b;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    opacity: 1;
    padding: 10px;
    width: 250px;
    z-index: 999999999;
    .bodyContainer {
      .dropdownContainer {
        margin: 20px 0;
        .label {
          color: #ffffff;
          font-size: 12px;
          cursor: pointer;
        }
        .dropdown {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}
