.mainContainer {
  .switchContainer {
    margin-top: 30px;
    .switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #17151d;
      border: 1px solid #2f2b2b;
      border-radius: 5px;
      padding: 20px;
      p {
        color: #ffffff;
        font-size: 15px;
      }
      .thumbContainer {
        width: 60px;
        height: 25px;
        border-radius: 20px;
        background-color: #bebebe;
        position: relative;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        .thumb {
          position: absolute;
          width: 30px;
          height: 30px;
          border: 2px solid #bebebe;
          border-radius: 50%;
          top: 50%;
          left: 0%;
          transform: translate(-50%, -50%);
          background-color: #414352;
          box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
            rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
          transition: 0.3s all ease-in-out;
          &::after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #f68f28;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 0.3s all ease-in-out;
          }
        }
      }
    }
  }

  .switchContainerActive {
    margin-top: 30px;
    .switch {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #17151d;
      border: 1px solid #2f2b2b;
      border-radius: 5px;
      padding: 20px;
      p {
        color: #ffffff;
        font-size: 15px;
      }
      .thumbContainer {
        width: 60px;
        height: 25px;
        border-radius: 20px;
        background-color: #f68f28;
        position: relative;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        .thumb {
          position: absolute;
          width: 30px;
          height: 30px;
          border: 2px solid #f68f28;
          border-radius: 50%;
          top: 50%;
          left: 100%;
          transform: translate(-50%, -50%);
          background-color: #414352;
          box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
            rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
          transition: 0.3s all ease-in-out;
          &::after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #f68f28;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 0.3s all ease-in-out;
          }
        }
      }
    }
  }
}
